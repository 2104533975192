import React, {useContext, useEffect, useState} from 'react'
import { DataContext } from '../../context/DataState';

import Swal from 'sweetalert2'

const ManualData = () => {
    let ContextValue = useContext(DataContext);
    const [course, setCourse] = useState([])
    const [trainer, setTrainer] = useState()
    const [remarkStatus, setRemarkStatus] = useState()
    const [manualData, setManualData] = useState({
        "counselorNo":localStorage.getItem("counsellorNo"),
        "counselorName":localStorage.getItem("counsellorName"),
        "statusRecord":{
        "remark":"",
        "date":""
    },
    }
    
)

    useEffect(()=>{

        getAllCourse()
        getAllTrainer()

    },[])

    const handleChange = (e) => {
        setManualData(prevState => ({
            ...prevState,
            statusRecord: {
                ...prevState.statusRecord,
                [e.target.name]: e.target.value
            }
        }));
    };



      const dateConvert = (selectedDate) => {
        const originalDate = new Date(selectedDate);
        const formattedDate = originalDate.toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        });
    
        return formattedDate;
      };

      const getAllTrainer = async () => {
        const allTrainer = await ContextValue.getTrainer();
        setTrainer(allTrainer.trainerData);
      };

      const getAllCourse = async () => {
        let allCourse = await ContextValue.getAllMainSubCourse();
        console.log("course =", allCourse, allCourse.courses);
        setCourse(allCourse.courses);
       
      };

      const addManualData = async()=>{
        console.log("add manualdata func =",manualData)

        ContextValue.updateProgress(60);
        ContextValue.updateBarStatus(true);

        try{
        let res = await fetch('https://blockey.in:8000/addmanualdata', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(manualData),
        });

        res = await res.json()

        ContextValue.updateProgress(100);
        ContextValue.updateBarStatus(false);
        if(res.status==true){
            Swal.fire({
                icon: "success",
                title: "",
                text: "",
              });
        }
        else{
            console.log("error from add manual data")
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
              });
        }
    }
    catch(error){

        console.log("error from add manual data error part",error.message)

        ContextValue.updateProgress(100);
        ContextValue.updateBarStatus(false);

        Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
    }
      }

      const handleTrainer = (e)=>{
    const selectedIndex = e.target.selectedIndex; // Get the selected option's index
    console.log("selected trainer =",trainer[selectedIndex-1],selectedIndex-1)

    setManualData({...manualData,["trainerId"]:trainer[selectedIndex-1].trainerId,["trainerName"]:trainer[selectedIndex-1].name})
      }

  return (
    <div>
        <div className="row">
              <div className="col-xl-12 col-xxl-12 col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title">Basic Info</h5>
                  </div>
                  <div>
                   <form action="#" method="post">
                      <div className="row">

                        <div className="edit-col col-lg-6 col-md-6 col-sm-12">
                          <div className="edit-form-group form-group">
                            <label className="form-label">Name</label>
                            <input
                              type="text"
                             
                              name="fullName"
                              class="form-control"
                              id="exampleInputPassword1"
                              onChange={e=>{setManualData({...manualData,[e.target.name]:e.target.value})}}
                            />
                          </div>
                          
                        </div>
                        <div className="edit-col col-lg-6 col-md-6 col-sm-12">
                          <div className="edit-form-group form-group">
                            <label className="form-label">Number</label>
                            <input
                              type="text"
                              max="10"
                              onChange={e=>{setManualData({...manualData,[e.target.name]:e.target.value})}}
                              name="contact"
                              class="form-control"
                              id="exampleInputPassword1"
                            />
                          </div>
                          
                        </div>
                        <div className="edit-col col-lg-6 col-md-6 col-sm-12">
                          <div className="edit-form-group form-group">
                            <label className="form-label">Email</label>
                            <input
                              type="email"
                              onChange={e=>{setManualData({...manualData,[e.target.name]:e.target.value})}}
                              name="email"
                              class="form-control"
                              id="exampleInputPassword1"
                            />
                          </div>
                          
                        </div>
                        <div className="edit-col col-lg-6 col-md-6 col-sm-12">
                          <div className="edit-form-group form-group">
                            <label className="form-label">Guardian</label>
                            <input
                              type="text"
                              onChange={e=>{setManualData({...manualData,[e.target.name]:e.target.value})}}
                              name="guardian"
                              class="form-control"
                              id="exampleInputPassword1"
                            />
                          </div>
                          
                        </div>
                        <div className="edit-col col-lg-6 col-md-6 col-sm-12">
                          <div className="edit-form-group form-group">
                            <label className="form-label">Location</label>
                            <input
                              type="text"
                              max="10"
                              onChange={e=>{setManualData({...manualData,[e.target.name]:e.target.value})}}
                              name="location"
                              class="form-control"
                              id="exampleInputPassword1"
                            />
                          </div>
                          
                        </div>
                        <div className="edit-col col-lg-6 col-md-6 col-sm-12">
                          <div className="edit-form-group form-group">
                            <label className="form-label">
                              Assigned Date
                            </label>
                            <input
                              name="assignedDate"
                              class="form-control"
                              type="date"
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                              onChange={e=>{setManualData({...manualData,[e.target.name]:e.target.value})}}
                            />
                          </div>
                          
                        </div>
                        
                        <div className="edit-col col-lg-6 col-md-6 col-sm-12">
                          <div className="edit-form-group form-group">
                            <label className="form-label">
                              Lead From
                            </label>
                            
                              <select
                                id="exampleInputPassword1"
                                type="select"
                                name="leadFrom"
                                class="form-control"      
                                onChange={e=>{setManualData({...manualData,[e.target.name]:e.target.value})}}
                              >
                                <option disabled selected>
                                  --Select Lead From--
                                </option>
                                
                                   <option value="whatsapp">By whataApp</option>
                                   <option value="instagram">By Instagram</option>
                                   <option value="social-media">By Social Media</option>
                            
                              </select>
                            
                          </div>
                          
                        </div>

                        <div className="edit-col flex-col col-lg-6 col-md-6 col-sm-12">
                          <div className="edit-form-group form-group">
                            <label className="form-label">Add Status</label>
                            <select
                                id="exampleInputPassword1"
                                type="select"
                                name="addStatus"
                                class="form-control" 
                                onChange={e=>{setManualData({...manualData,[e.target.name]:e.target.value});(e.target.value=="noResponse" || e.target.value=="notInterested")?setRemarkStatus(false):setRemarkStatus(true)} }  
                              >
                                <option disabled selected>
                                  --Select Status--
                                </option>
                                
                                   <option value="demo">Demo</option>
                                   <option value="visit">Visit</option>
                                   <option value="followup">Follow Up</option>
                                   <option value="noResponse">No Response</option>
                                   <option value="notInterested">Not Interested</option>
                            
                              </select>
                          </div>
                          
                          {remarkStatus && <div className="date-time-section">
                              <div className="edit-col">
                              <div className="date-sec">
                              <label className="form-label">Date</label>
                                <input
                                  type="date"                                
                                  onChange={handleChange} 
                                  name="date"
                                  className="form-control"
                                />
                              </div>
                            
                              </div>
                              <div className="edit-col">
                              <div className="time-sec">
                                <label className="form-label">Remark</label>
                                <input
                                 type="text"                              
                                 onChange={handleChange} 
                                  name="remark"
                                  className="form-control"
                                ></input>
                              </div>
                              
                              </div>
                            </div>}
                        </div>                    

                        <div className="edit-col col-lg-6 col-md-6 col-sm-12">
                          <div className="edit-form-group form-group">
                            <label className="form-label">Course Name</label>
                            {course && (
                              <select
                                id="exampleInputPassword1"
                                type="select"
                                name="course"
                                class="form-control"
                                onChange={e=>{setManualData({...manualData,[e.target.name]:e.target.value});console.log("course value")}}
                              >
                                <option disabled selected>
                                  --select Course Name--
                                </option>
                                {course.map((data) => {
                                  return <option value={data.course}>{data.course}</option>;
                                })}
                              </select>
                            )}
                          </div>
                          
                        </div>
                             
                           

                        <div className="edit-col col-lg-6 col-md-6 col-sm-12">
                          <div className="edit-form-group form-group">
                            <label className="form-label">Trainer</label>
                            {trainer && (
                              <select
                                id="exampleInputPassword1"
                                type="select"
                                name="Course"
                                class="form-control"
                                onChange={e=>{handleTrainer(e)}}
                              >
                                <option disabled selected>
                                  --select Trainer--
                                </option>
                                {trainer.map((data) => {
                                  return <option value={data.name}>{data.name}</option>;
                                })}
                              </select>
                            )}
                          </div>
                          
                        </div>                       
                      </div>
                    </form>
                    
                    <div className="edit-col edit-col col-lg-6 col-lg-12 col-md-6 col-sm-12 mt-2">
                       
                        <button type="submit" className="btn btn-primary" onClick={addManualData}>
                          Submit
                        </button>

                      
                        <button type="submit" className="btn btn-light">
                          Cancel
                        </button>
                      </div>
                  </div>
                </div>
              </div>
            </div>
    </div>
  )
}

export default ManualData