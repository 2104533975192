import React, { useEffect, useState, useContext } from 'react';
import {DataContext} from '../../../context/DataState'
import Swal from "sweetalert2";
import CounsellorFilter from '../../common/CounsellorFilter';

const CounsellorConnected = () => {
  const [allCounsellor, setAllCounsellor] = useState([])
  const [followUpData, setFollowUpData] = useState([])
  const [visitData, setVisitData] = useState([])
  const [trainer, setTrainer] = useState()

  const [demoData, setDemoData] = useState([])

  let ContextValue = useContext(DataContext);
  // console.log('context value before=',ContextValue)

  useEffect(()=>{


    getAllCounsellor()
    // getLatestData()
    // getLead()
    getAllTrainer()

  },[])

 

  const addDemo = (element, index, value) => {
    console.log('Index of student and student:', index);
  
    const trainerOptions = trainer.map((data, i) => (
      `<option value="${i}">${data.name}</option>`
    )).join('');
  
    Swal.fire({
      title: 'Add Demo Date',
      html: `
        <input id="demoDate" type="date" class="swal2-input" placeholder="Add Date">
        <select id="trainer" class="swal2-input">
          <option value="">Select Trainer</option>
          ${trainerOptions}
        </select>
        <input id="remark" type="text" class="swal2-input" placeholder="Add Remark">
      `,
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Add',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        const demoDate = document.getElementById('demoDate').value;
        const remark = document.getElementById('remark').value;
        const trainerIndex = document.getElementById('trainer').value;

        const trainerName = trainer[trainerIndex].name
        const trainerId = trainer[trainerIndex].trainerId

        console.log("index =",index,trainerId, trainerName,trainer)
  
        if (!demoDate || !trainer) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Please fill out all fields!'
          });
          return;
        }
  
        let tempDemoData = demoData;
        let tempLeadData = ContextValue.latestData;
  
        tempLeadData[index].scheduleDate = demoDate;
        tempLeadData[index].finalStatus = "";
        tempLeadData[index].finalStatusFrom = "";
        tempLeadData[index].finalDate = "";
  
        let objNew = {};
        let obj = {};
  
        element.students.forEach(data => {
          objNew[data.name] = data.values;
        });
  
        obj.demoStudent = objNew;
        obj.finalStatus = "";
        obj.finalStatusFrom = "";
        obj.finalDate = "";
        obj.date = element.assignedDate;
        obj.status = "Demo";
        obj.demoStatus = "";
        obj.counselorNo = element.counsellorNo;
        obj.campaignId = element.campaignId;
        obj.campaignName = element.campaignName;
        obj.remarks = element.remarks;
        obj.id = element.id;
        obj.reschedule = demoDate;
        obj.demoRecord=[{
          date:demoDate,
          remark:remark
        }]
        obj.scheduleDate = formatDate(new Date());
        obj.trainer = trainerName;
        obj.trainerId = trainerId;
  
        tempDemoData.push(obj);
        setDemoData(tempDemoData);
        ContextValue.updateLatestData(tempLeadData)
        tempLeadData[index].status = value;
        // setLeadData(tempLeadData);
  
        console.log("Updated demo data:", tempDemoData);
        
  
        Swal.fire({
          icon: 'success',
        });
      }
    });
  };

  const getAllCounsellor = async()=>{

    let allCounselor = await ContextValue.getAllCounselor();

    setAllCounsellor(allCounselor.counselorData)
  }


const formatDate = (date) => {
const day = String(date.getDate()).padStart(2, '0');
const month = String(parseInt(date.getMonth()) + 1).padStart(2,'0')
const year = date.getFullYear();

return (`${year}-${month}-${day}`)
};


const addLeadStatus = async (e) => {

  console.log("lead status route working")

  ContextValue.updateProgress(30);
  ContextValue.updateBarStatus(true);

  e.preventDefault();

  let tempAllLeadData = ContextValue.latestData;

  console.log("from route =",tempAllLeadData)

  try 
  {
    let url = `https://blockey.in:8000/counselorLead`
    ContextValue.updateProgress(20);

    const res = await fetch(`${url}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("counsellor")
      },
      body: JSON.stringify(tempAllLeadData),
    });

    ContextValue.updateProgress(60);

    const data = await res.json();

    ContextValue.updateProgress(100);
    ContextValue.updateBarStatus(false);

  }

  catch(error) {
    ContextValue.updateProgress(100);
    ContextValue.updateBarStatus(false);
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong!",
    });

    console.log("error =", error.message);
  }



    if(demoData.length>0){

      console.log("demo data length =",demoData)
      try {

        let url = `https://blockey.in:8000/counselorDemo`
        ContextValue.updateProgress(60);
  
        const res = await fetch(`${url}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("counsellor"),
            "status":"allLead"
          },
          body: JSON.stringify(demoData),
        });
  
        ContextValue.updateProgress(60);
  
        const data = await res.json();

        ContextValue.updateProgress(100);
        ContextValue.updateBarStatus(false);
      }

      catch(error) {
        ContextValue.updateProgress(100);
        ContextValue.updateBarStatus(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
    
        console.log("error =", error.message);
      }

    }
  
        if (visitData.length>0){
          console.log("if from visit and followup length",visitData)
          try {
  
            let url = `https://blockey.in:8000/counselorVisit`
            ContextValue.updateProgress(60);
      
            const res = await fetch(`${url}`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "auth-token": localStorage.getItem("counsellor"),
                "status":"allLead"
              },
              body: JSON.stringify(visitData),
            });
      
            ContextValue.updateProgress(60);
      
            const data = await res.json();
            ContextValue.updateProgress(100);
                ContextValue.updateBarStatus(false);
      
            console.log("progress bar 100")

          }
  
          catch(error) {
            ContextValue.updateProgress(100);
            ContextValue.updateBarStatus(false);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
        
            console.log("error =", error.message);
          }
        }


            if(followUpData.length>0){
              console.log("follow up data length", followUpData.length)
              try {
  
                let url = `https://blockey.in:8000/counselorFollowUp`
                ContextValue.updateProgress(60);
          
                const res = await fetch(`${url}`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    "auth-token": localStorage.getItem("counsellor"),
                    "status":"allLead"
                  },
                  body: JSON.stringify(followUpData),
                });
          
                ContextValue.updateProgress(60);
          
                const data = await res.json();
                ContextValue.updateProgress(100);
                ContextValue.updateBarStatus(false);
          
                console.log("progress bar 100")
              }
              catch(error) {
                ContextValue.updateProgress(100);
                ContextValue.updateBarStatus(false);
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                });
            
                console.log("error =", error.message);
              }
            }
  

};

const addVisit = (element, index, value)=>{

  console.log(' index of student =',index, visitData)
  Swal.fire({
      title: 'Add Visit Date',
      html:
          `<input id="visitDate" type="date" class="swal2-input" placeholder="Add Date">
          <input id="remark" type="text" class="swal2-input" placeholder="Add Remark">`,
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Add',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {

        const visitDate = document.getElementById('visitDate').value;
        const remark = document.getElementById('remark').value;

        
        let tempVisitData = visitData;

        let objNew = {}
        let obj ={}

        element.students.map(data=>{
          
          objNew[data.name] = data.values

        })
      
          obj.students = objNew
          obj.date = element.assignedDate
          obj.finalStatus = ""
          obj.finalStatusFrom = ""
          obj.finalDate = ""
          obj.visitTrainer = ""
          obj.visitCounsellor = ""
          obj.visitDate = visitDate
          obj.visitStatus = "Visit"
          obj.visitRecord = [{
            date:visitDate,
            remark:remark
          }]
          obj.campaignId = element.campaignId
          obj.campaignName = element.campaignName
          obj.remarks = element.remarks
          obj.id = element.id
       

        tempVisitData.push(obj)
        setVisitData(tempVisitData)

        let tempLeadData = ContextValue.latestData;
        tempLeadData[index].status=value
        tempLeadData[index].scheduleDate=visitDate
        tempLeadData[index].finalStatus = ""
        tempLeadData[index].finalStatusFrom = ""
        tempLeadData[index].finalDate = ""
        // setLeadData(tempLeadData)
        ContextValue.updateLatestData(tempLeadData)

        console.log("tempVisitData =",tempVisitData, tempLeadData)

        Swal.fire({
          title: `${result.value}`,
          
          imageUrl: result.value.avatar_url
        })
      }
    })

}

const addFollowUp = (element, index, value)=>{

  console.log(' index of student =',index)
  Swal.fire({
      title: 'Add Reschedule Date',
      html:
          `<input id="followUpDate" type="date" class="swal2-input" placeholder="Add Follow Up Date">
          <input id="remark" type="text" class="swal2-input" placeholder="Add Remark">`,
      inputAttributes: {
      autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Add',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {

        const followUpDate = document.getElementById('followUpDate').value;
        const remark = document.getElementById('remark').value;
        

        let tempFollowUpData = followUpData;

        let obj = {}
        let objNew = {}

        element.students.map(data=>{
          
          objNew[data.name] = data.values

        })

          obj.students = objNew
          obj.status="Follow Up"
          obj.FollowUp=[{
            date:followUpDate,
            remark:remark
          }]
          obj.lastFollowUpDate=followUpDate
          obj.status="Follow Up"
          obj.finalStatus = ""
          obj.finalStatusFrom = ""
          obj.finalDate = ""
          obj.date=element.assignedDate
          obj.campaignId = element.campaignId
          obj.campaignName = element.campaignName
          obj.remarks = element.remarks
          obj.id=element.id
       

        tempFollowUpData.push(obj)
        setFollowUpData(tempFollowUpData)

        let tempLeadData = ContextValue.latestData;
        tempLeadData[index].status=value
        tempLeadData[index].scheduleDate=followUpDate
        tempLeadData[index].finalStatus = ""
        tempLeadData[index].finalStatusFrom = ""
        tempLeadData[index].finalDate = ""
        
        // setLeadData(tempLeadData)
        ContextValue.updateLatestData(tempLeadData)


        console.log("temp demo student =",tempFollowUpData, tempLeadData)

        Swal.fire({
          title: `${result.value}`,
          
          imageUrl: result.value.avatar_url
        })
      }
    })
}

      const getAllTrainer = async()=>{

        let allTrainer = await ContextValue.getTrainer();
    
        console.log("allTrainer.trainerData", allTrainer.trainerData, allTrainer )
    
        setTrainer(allTrainer.trainerData)
      }

      const addStatus  =(value,element,index)=>{

        console.log('value and index status =',value,index,element)
      
        if(ContextValue.latestData[index].status=="")
        {
          
        if(value=="Demo"){
          addDemo(element,index, value)
        }
        else if(value=="Visit"){
          addVisit(element, index, value)
        }
        else if(value=="Follow Up"){
          addFollowUp(element, index, value)
        }
        else if(value=="Registered" || value=="Not Interested"){
          let tempLatestData = ContextValue.latestData;
          tempLatestData[index].status = value
          tempLatestData[index].finalStatus = value
          tempLatestData[index].finalStatusFrom = "Direct"
          tempLatestData[index].finalDate = formatDate(new Date())
          console.log("else is running registered",tempLatestData)
          // setLatestData(tempLatestData)
          ContextValue.updateLatestData(tempLatestData)
        }
        else{
          let tempLatestData = ContextValue.latestData;
          tempLatestData[index].status = value
          tempLatestData[index].finalStatus = ""
          tempLatestData[index].finalStatusFrom = ""
          tempLatestData[index].finalDate = ""
          tempLatestData[index].scheduleDate = formatDate(new Date())
          console.log("else is running",tempLatestData)
          // setLatestData(tempLatestData)
          ContextValue.updateLatestData(tempLatestData)
        }
      }
      
      else if(ContextValue.latestData[index].status!=value)
        // console.log("else latest data value  =",latestData)
        {
      
        if(ContextValue.latestData[index].status=="Demo"){
          let tempDemoData = demoData;
          tempDemoData.map(data=>{
              if(data.id==element.id){
                data.status="Changed"
              }
          })
      
          console.log("demo student after filter =",tempDemoData)

          setDemoData(tempDemoData)
        }
      
        else if(ContextValue.latestData[index].status=="Visit")
          {
          let tempVisitData = visitData
          tempVisitData.map(data=>{
              if(data.id==element.id){
                data.visitStatus="Changed"
              }
          })
      
          console.log("temp visit data =",tempVisitData)
      
          setVisitData(tempVisitData)
        }
       
        else if(ContextValue.latestData[index].status=="Follow Up"){
          console.log("follow up status = ",followUpData)
          let tempFollowUpData = followUpData
          tempFollowUpData.map(data=>{
  
              if(data.id==element.id){
                data.status="Changed"
              }
          })
      
          setFollowUpData(tempFollowUpData)
        }
      
        if(value=="Demo"){
          addDemo(element,index, value)
        }
        else if(value=="Visit"){
          addVisit(element, index, value)
        }
        else if(value=="Follow Up"){
          addFollowUp(element, index, value)
        }

        else if(value=="Registered" || value=="Not Interested"){
          let tempLatestData = ContextValue.latestData;
          tempLatestData[index].status = value
          tempLatestData[index].finalStatus = value
          tempLatestData[index].finalStatusFrom = "Direct"
          tempLatestData[index].finalDate = formatDate(new Date())
          console.log("else is running registered",tempLatestData)
          // setLatestData(tempLatestData)
          ContextValue.updateLatestData(tempLatestData)
        }
        else{
          let tempLatestData = ContextValue.latestData;
          tempLatestData[index].status = value
          tempLatestData[index].finalStatus = ""
          tempLatestData[index].finalStatusFrom = ""
          tempLatestData[index].finalDate = ""
          tempLatestData[index].scheduleDate = formatDate(new Date())
          console.log("else is running",tempLatestData)
          // setLatestData(tempLatestData)
          ContextValue.updateLatestData(tempLatestData)
        }
      }
      
      }


      const setRemarks =(value,index)=>{

        console.log("remarks value =",value,index)
        let tempLatestData = ContextValue.latestData;

        tempLatestData[index].remarks = value
        // setLatestData(tempLatestData)
        ContextValue.updateLatestData(tempLatestData)

        console.log("tempLatestData =",tempLatestData)

      }


 return(
    <div>
            <h3>Registered - {ContextValue.latestData.length}</h3>
        
      <CounsellorFilter function="getCounsellorRingingConnectedRegisteredFilter" status="Connected" counselorNo={localStorage.getItem("counsellorNo")} />

            <button onClick={addLeadStatus} disabled={ContextValue.latestData.length>0?false:true}>Add Status</button>      
          {ContextValue.latestData.length>0 &&
            <div className="table-container"> <table>

            <tr>
              <th>Campaign</th>
              <th>Assigned Date</th>
              <th>Name</th>
              <th>City</th>
              <th>Contact</th>
              <th>Email</th>


          <th>Status</th>
              <th>Remarks</th>
            </tr>

              {ContextValue.latestData.map((element,index)=>{
        return(
          <tr>
            { element.students && (
              <>
  <td>{ContextValue.latestData[index].campaignName}</td>
  <td>{ContextValue.latestData[index].assignedDate}</td>
  {
 
    element.students.map(data => {
      if (data.name === "full_name" || data.name=="full name") {
        return (
          <td key={data.id}> {/* Replace `data.id` with a unique key */}
            {data.values}
          </td>
        );
      }
      return null; // Return null if no match, to avoid errors
    })
    
  }
  {
 
    element.students.map(data => {
      if (data.name === "city") {
        return (
          <td key={data.id}> {/* Replace `data.id` with a unique key */}
            {data.values}
          </td>
        );
      }
      return null; // Return null if no match, to avoid errors
    })
    
  }
  {
 
    element.students.map(data => {
      if (data.name === "phone_number") {
        return (
          <td key={data.id}> {/* Replace `data.id` with a unique key */}
            {data.values}
          </td>
        );
      }
      return null; // Return null if no match, to avoid errors
    })
    
  }
  {
 
    element.students.map((data,dataIndex) => {
      if (data.name === "email") {
        return (
          <td key={data.id}> {/* Replace `data.id` with a unique key */}
            {data.values}
          </td>
        );
      }
      return null; // Return null if no match, to avoid errors
    })
    
  }
  {allCounsellor.length > 0 &&
    <td>
         <select
                        id="exampleInputPassword1"
                        type="select"
                        name="leadfrom"
                        class="custom-select mr-sm-2"
                        onChange={(e)=>{
                          addStatus(e.target.value,element,index)
                        }}
                        defaultValue={element.status?element.status:"selected"}                      
                    >
                        <option selected disabled value="selected">-- Select Status--</option>
                    
                                <option value="Demo">Demo</option>
                                <option value="Visit">Visit</option>
                                <option value="Follow Up">Follow Up</option>                     
                                <option value="Ringing">Ringing</option>                     
                                <option value="Connected">Connected</option>                     
                                <option value="Registered">Registered</option>                                         
                                <option value="Not Interested">Not Interested</option>                                         
                        
                    </select>
    </td>
  }
  <td onChange={e=>setRemarks(e.target.value,index)}><input defaultValue={element.remarks}/></td>
</>)}</tr>

        )
      })}
          </table></div>
}        </div>
 )
};

export default CounsellorConnected;
