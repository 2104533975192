import React, { useEffect, useState, useContext } from 'react';
import { DataContext } from '../../../context/DataState';
import Swal from "sweetalert2";
import CounsellorFilter from '../../common/CounsellorFilter';

const CounsellorVisit = () => {
  const [allCounsellor, setAllCounsellor] = useState([]);
  let ContextValue = useContext(DataContext);
  console.log('context value before=', ContextValue);

  useEffect(() => {
    console.log('context value =', ContextValue);
    getAllCounsellor();
  }, []);

  const getReVisitDate = (index, from) => {
    console.log('index of student =', index);
    Swal.fire({
      title: 'Add Revisit Date',
      html: `<input id="reVisitDate" type="date" class="swal2-input" placeholder="Add Date">
      <input id="remark" type="text" class="swal2-input" placeholder="Add Remark">`,
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Add',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        const reVisitDate = document.getElementById('reVisitDate').value;
        const remark = document.getElementById('remark').value;
        let tempVisitData = ContextValue.latestData;
        tempVisitData[index].visitDate = reVisitDate;
        tempVisitData[index].visitRecord.push({
          date:reVisitDate,
          remark:remark
        })
        tempVisitData[index].finalStatus = "";
        tempVisitData[index].finalStatusFrom = "";
        tempVisitData[index].finalDate = "";
        ContextValue.updateLatestData(tempVisitData);
        Swal.fire({
          title: `Re Visit Date has been added`,
          imageUrl: result.value.avatar_url
        });
      }
    });
  };

  const getAllCounsellor = async () => {
    let allCounselor = await ContextValue.getAllCounselor();
    setAllCounsellor(allCounselor.counselorData);
  };

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(parseInt(date.getMonth()) + 1).padStart(2, '0');
    const year = date.getFullYear();
    return (`${year}-${month}-${day}`);
  };

  const setVisitStatus = (name, value, index, from) => {
    console.log("inside visit status", value);
    let tempVisitData = ContextValue.latestData;
    tempVisitData[index].visitStatus = value;
    tempVisitData[index].visitDate = "";
    tempVisitData[index].finalStatus = value;
    tempVisitData[index].finalStatusFrom = "Visit";
    tempVisitData[index].finalDate = formatDate(new Date());
    ContextValue.updateLatestData(tempVisitData);

    if (value === "Visit") {
      console.log("inside if condition", value);
      getReVisitDate(index, from);
    }
  };

  const updateVisit = async (e) => {
    ContextValue.updateProgress(30);
    ContextValue.updateBarStatus(true);

    e.preventDefault();

    try {
      let url = `https://blockey.in:8000/counselorVisit`;
      ContextValue.updateProgress(60);

      const res = await fetch(`${url}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("counsellor"),
          "status": "fromVisit"
        },
        body: JSON.stringify(ContextValue.latestData),
      });

      ContextValue.updateProgress(60);
      ContextValue.updateProgress(100);
      ContextValue.updateBarStatus(false);

      console.log("progress bar 100");

    } 
    catch (error) {
      ContextValue.updateProgress(100);
      ContextValue.updateBarStatus(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
      console.log("error =", error.message);
    }
  };

  return (
    <div>
      <h3>Visit - {ContextValue.latestData.length}</h3>
      <CounsellorFilter function="getVisitFilter" counselorNo={localStorage.getItem("counsellorNo")} />
      <button
        type="submit"
        onClick={updateVisit}
        className="btn btn-primary mt-2"
      >
        Submit Visit
      </button>

      {ContextValue.latestData.length > 0 &&
        <div className="table-container">
          <table>
            <tr>
              <th>Name</th>
              <th>City</th>
              <th>Contact</th>
              <th>Email</th>
              <th>Visit Status</th>
              <th>History</th>
            </tr>
            {ContextValue.latestData.map((element, index) => (
              <tr>
                {
                  element.students && (
                    <>
                {Object.keys(element.students).map((key) => {
                  const data = element.students[key];
                  if (key === "full_name" || key === "full name") {
                    return <td key={element.id}>{data}</td>;
                  }
                  return null;
                })}
                {Object.keys(element.students).map((key) => {
                  const data = element.students[key];
                  if (key === "city") {
                    return <td key={element.id}>{data}</td>;
                  }
                  return null;
                })}
                {Object.keys(element.students).map((key) => {
                  const data = element.students[key];
                  if (key === "phone_number") {
                    return <td key={element.id}>{data}</td>;
                  }
                  return null;
                })}
                {Object.keys(element.students).map((key) => {
                  const data = element.students[key];
                  if (key === "email") {
                    return <td key={element.id}>{data}</td>;
                  }
                  return null;
                })}
                {allCounsellor.length > 0 &&
                  <td>
                    <select
                      id="exampleInputPassword1"
                      name="leadfrom"
                      className="custom-select mr-sm-2"
                      defaultValue={element.visitStatus !== "Visit" ? element.visitStatus : "selected"}
                      onChange={(e) => setVisitStatus(e.target.name, e.target.value, index, "from today visit")}
                    >
                      <option disabled selected value="selected">--Select Visit Status--</option>
                      <option value="Not Visited">Not Visited</option>
                      <option value="Not Interested">Not Interested</option>
                      <option value="Visit">Re Visit</option>
                      <option value="Registered">Registered</option>
                    </select>
                  </td>
                }
                <td onClick={() => { ContextValue.updateHistory(true, element, "visit", element.students['full_name'] || element.students['full name']) }}><i class="fa-solid fa-clock-rotate-left"></i></td>
           </> )}</tr>
            ))}
          </table>
        </div>
      }
    </div>
  );
};

export default CounsellorVisit;
