import React, {useContext,useEffect,useState} from 'react'
import {DataContext} from '../../context/DataState'
const AdminFilter = (props) => {

  let functionName = props.function
  let status = props.status?props.status:""
  let fromStatus = props.fromStatus?props.fromStatus:false
  let ContextValue = useContext(DataContext);

  const [allCounsellor, setAllCounsellor] = useState([])
  const [allCampaign, setAllCampaign] = useState([])
  const [from, setFrom] = useState("")
  const [timeValue,setTimeValue] = useState() 
  const [rangeDate, setRangeDate]=  useState({
    startDate:"",
    endDate:""
  })
  const [campaign, setCampaign] = useState({
    name:"",
    id:""
  })
  const [counsellor, setCounsellor] = useState({
    name:"",
    counsellorNo:""
  })

  useEffect(()=>{

    let tempDate = formatDate(new Date())

    console.log("today date =",tempDate)

    let tempRangeDate = rangeDate;

    tempRangeDate.startDate = tempDate;
    tempRangeDate.endDate = tempDate

    getCounsellorCampaign()
    getLeadFilter()
  },[])

  const getLeadFilter = async () => {


    ContextValue.updateBarStatus(true);

   let totalLead = await ContextValue[functionName](counsellor.counsellorNo,campaign.id,rangeDate.startDate,rangeDate.endDate,from,status)

    ContextValue.updateProgress(100);
    ContextValue.updateBarStatus(false);

   console.log("get lead filter admin ",totalLead)
  };

  const setCounsellorDetail =(value)=>{


    let tempCounsellor = counsellor;
    if(value!="All"){
      tempCounsellor.name = allCounsellor[value].Name
      tempCounsellor.counsellorNo = allCounsellor[value].counselorNo
    }

    else{
      tempCounsellor.name = ""
      tempCounsellor.counsellorNo = ""
    }
 

    setCounsellor(tempCounsellor)
    console.log("value of counsellor =",tempCounsellor)

  }

  const setCampaignDetail =(value)=>{

    let tempCampaign = campaign;
    if(value!="All"){
    tempCampaign.name = allCampaign[value].name
    tempCampaign.id = allCampaign[value].id
    }

    else{
      tempCampaign.name = ""
      tempCampaign.id = ""
    }

    setCampaign(tempCampaign)
    console.log("value of campaign =",tempCampaign)

  }

  const setFromTime =(fromTime)=>{
    // const startDateStr =  formatDate(new Date(fromTime))
    setRangeDate({...rangeDate, ["startDate"]:fromTime})
    // console.log("from time ",startDateStr)
    
   }
   const setToTime =(toTime)=>{
    // const endDateStr = formatDate(new Date(toTime))
    setRangeDate({...rangeDate, ["endDate"]:toTime})
    // console.log("to time ",endDateStr)
   }

   const setStartEndate = (timeValue) => {
    console.log("start and end date =", timeValue);
    let today = new Date();
    let startDate, endDate;
  
    if (timeValue === "Today") {
        startDate = today;
        endDate = new Date(today);
    } else if (timeValue === "Yesterday") {
        today.setDate(today.getDate() - 1); // Subtract 1 day to get yesterday
        startDate = today;
        endDate = new Date(today);
    } else if (timeValue === "Last Week") {
        endDate = new Date(); // Current date
        startDate = new Date();
        startDate.setDate(endDate.getDate() - 7); // Subtract 7 days to get a week ago
    }
    else if (timeValue === "Tomorrow") {
      today.setDate(today.getDate() + 1); // Add 1 day to get tomorrow
      startDate = today;
      endDate   = new Date(today);
  }
    else {
        // Handle the case when time is not recognized
        console.error("Invalid time option");
        return;
    }
  
    // Add one day to endDate
    // endDate.setDate(endDate.getDate() + 1);
  
    const startDateStr = formatDate(startDate);
    const endDateStr = formatDate(endDate);
    console.log("start date and end date =", startDateStr, endDateStr);
    setRangeDate({...rangeDate, ["startDate"]: startDateStr, ["endDate"]: endDateStr});
  
    return { startDate: startDateStr, endDate: endDateStr };
};

const formatDate = (date) => {
  const day = String(date.getDate()).padStart(2, '0');
const month = String(parseInt(date.getMonth()) + 1).padStart(2,'0')
const year = date.getFullYear();

return (`${year}-${month}-${day}`)
};

const getCounsellorCampaign = async()=>{

  await getAllCampaign()
  await getAllCounsellor()

}

const getAllCounsellor = async()=>{

  let allCounselor = await ContextValue.getAllCounselor();
  console.log("all counselor =",allCounselor)

  setAllCounsellor(allCounselor.counselorData)
}
const getAllCampaign = async()=>{

  let allCampaign = await ContextValue.getAllCampaign();

  console.log("all campaign =",allCampaign)

  setAllCampaign(allCampaign.data)
}

  return (
    <div>
      <div className='filter-section'>
<select
                        id="exampleInputPassword1"
                        type="select"
                        name="Course"
                        class="custom-select mr-sm-2 filter-btn"
                        onChange={e =>{ setTimeValue(e.target.value);setStartEndate(e.target.value)}}
                    >
                        <option disabled selected>--select Time--</option>
                    
                                <option value="Today">Today</option>
                                <option value="Tomorrow">Tomorrow</option>
                                <option value="Yesterday">Yesterday</option>
                                <option value="Last Week">Last Week</option>
                                <option value="Select Range">Select Range</option>
                        
                        
                    </select>

                     {timeValue==="Select Range" && 
                     <>
                     <label>From</label>
                      <input type="date" class="custom-select mr-sm-2" onChange={e=>setFromTime(e.target.value)}></input>
                      <label>To</label>
                      <input type="date" class="custom-select mr-sm-2" onChange={e=>setToTime(e.target.value)}></input>
                      </>}

                    { allCampaign.length>0 &&  
                    <select className='filter-btn'
                    onChange={e=>{setCampaignDetail(e.target.value)}}>
          <option selected disabled>select campaign</option>
          <option value="All">All</option>
          {
            allCampaign.map((data,index)=>{
              return(
                <option value={index}>{data.name}</option>
              )
            })
          }
        </select>}
      {allCounsellor.length>0 &&  <select className='filter-btn'
      onChange={e=>{setCounsellorDetail(e.target.value)}}>
          <option selected disabled>select Counsellor</option>
          <option value="All">All</option>
        {
          allCounsellor.map((data,index)=>{
                return(
                  <option value={index}>{data.Name}</option>
                )
          })
        }
        </select>}

       {fromStatus && <select className='filter-btn' onChange={e=>{e.target.value!="All"?setFrom(e.target.value):setFrom("")}}>
          <option selected disabled>--select From--</option>
          <option value="All">All</option>
          <option value="Demo">Demo</option>
          <option value="Visit">Visit</option>
          <option value="Follow Up">Follow Up</option>
          <option value="Direct">Direct</option>
        </select>}

       
       
       <button onClick={getLeadFilter}>Search</button>
        </div>
    </div>

  )
}

export default AdminFilter