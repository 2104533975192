import React, { useEffect, useContext } from 'react';
import { DataContext } from '../../../context/DataState';
import AdminFilter from '../../common/AdminFilter';

const FollowUp = () => {
  const ContextValue = useContext(DataContext);
  console.log('context value before=', ContextValue);

  useEffect(() => {
    // Any side effects or API calls can go here
  }, []);

  return (
    <div>
      <h3>All Follow Up - {ContextValue.latestData.length}</h3>

      <AdminFilter function="getFollowUpFilter" />

      {ContextValue.latestData.length > 0 && (
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Campaign</th>
                <th>Created Time</th>
                <th>Name</th>
                <th>City</th>
                <th>Contact</th>
                <th>Email</th>
                <th>Assigned Counsellor</th>
                <th>History</th>
              </tr>
            </thead>
            <tbody>
              {ContextValue.latestData.map((element, index) => (
                <tr key={index}>
                  <td>{element.campaignName}</td>
                  <td>{element.date}</td>

                  {/* Checking if element.students exists */}
                  {element.students &&
                    Object.keys(element.students).map((key) => {
                      const data = element.students[key];
                      if (key === 'full_name' || key === 'full name') {
                        return <td key={element.id}>{data}</td>;
                      }
                      return null;
                    })}

                  {element.students &&
                    Object.keys(element.students).map((key) => {
                      const data = element.students[key];
                      if (key === 'city') {
                        return <td key={element.id}>{data}</td>;
                      }
                      return null;
                    })}

                  {element.students &&
                    Object.keys(element.students).map((key) => {
                      const data = element.students[key];
                      console.log('data =', data, key);
                      if (key === 'phone_number') {
                        return <td key={element.id}>{data}</td>;
                      }
                      return null;
                    })}

                  {element.students &&
                    Object.keys(element.students).map((key) => {
                      const data = element.students[key];
                      if (key === 'email') {
                        return <td key={element.id}>{data}</td>;
                      }
                      return null;
                    })}

                  {ContextValue.allCounsellor.length > 0 && (
                    <td>
                      <select
                        name="Course"
                        className="custom-select mr-sm-2 assigned-counsellor-class"
                        defaultValue={
                          (ContextValue.allCounsellor.find(
                            (item) => item.counselorNo === element.counselorNo
                          ) || {}).Name
                        }
                      >
                        {ContextValue.allCounsellor.map((data, index) => (
                          <option key={index} value={data.Name}>
                            {data.Name}
                          </option>
                        ))}
                      </select>
                    </td>
                  )}

<td onClick={() => { ContextValue.updateHistory(true, element, "followup", element.students['full_name'] || element.students['full name']) }}><i class="fa-solid fa-clock-rotate-left"></i></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default FollowUp;
