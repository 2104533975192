import React, { useEffect, useState, useContext } from 'react';
import {DataContext} from '../../../context/DataState'
import Swal from "sweetalert2";

const AllLead = () => {
  const [activeSection, setActiveSection] = useState('section-1');
  const [latestData, setLatestData] = useState([])
  const [currentData, setCurrentData] = useState([])
  const [allCounsellor, setAllCounsellor] = useState([])
  const [allCampaign, setAllCampaign] = useState([])
  const [counsellorStatus, setCounsellorStatus] = useState(false)
  const [todayDate, setTodayDate] = useState()
  const [addLeadStatus, setAddLeadStatus] = useState(false)
  const [campaignStatus, setCampaignStatus] = useState(false)
  const [counsellor, setCounsellor] = useState({
    counselorNo:"",
    counselorName:""
  })
  const [campaign, setCampaign] = useState({
    name:"",
    id:""
  })

  let ContextValue = useContext(DataContext);
  console.log('context value before=',ContextValue)

  useEffect(()=>{

    setAddLeadStatus(false)

    console.log('context value =',ContextValue)

    let tempDate  = formatDate(new Date())
    setTodayDate(tempDate)

    console.log("formate date =",tempDate)

    if (ContextValue){
    console.log("if condition useffect")
    getCounsellorLatestData()
   
    }

  },[addLeadStatus])

  const getCounsellorLatestData = async()=>{
    await getAllCounsellor()
    await getLatestData()
    await getAllCampaign()
  }

  const getAllCounsellor = async()=>{

    let allCounselor = await ContextValue.getAllCounselor();

    setAllCounsellor(allCounselor.counselorData)
  }

  const getAllCampaign = async()=>{

    let allCampaign = await ContextValue.getAllCampaign();

    console.log("all campaign =",allCampaign)

    setAllCampaign(allCampaign.data)
  }


  const getCounsellorCampaign = async()=>{

    await getAllCampaign()
    await getAllCounsellor()

  }

  const getLatestData = async()=>{
    console.log("get latest data is running")
    setLatestData([])
    setCurrentData([])

    console.log("get latest")
    let leadData = await ContextValue.getLatestData()
    setLatestData(leadData)
    setCurrentData(leadData)
    console.log("lead data =",leadData)
  }

  const setAssignedCounsell = (index)=>
    {

      var selectElement = document.getElementsByClassName("assigned-counsellor-class");

      // let tempArr = tempObj[allCounsellor[selectElement[index].selectedIndex].counselorNo]

      let counsellorIndex = selectElement[index].selectedIndex
      let dataIndex = index

    console.log("value =",selectElement[index].selectedIndex,index)

    let tempAllLead = latestData

    tempAllLead[dataIndex].counsellorNo = allCounsellor[counsellorIndex].counselorNo;
    tempAllLead[dataIndex].counsellorName = allCounsellor[counsellorIndex].Name

    setLatestData(tempAllLead)


  }
  const setAssignedCounsellor = (index)=>
    {
      let tempLatestData = currentData;
      setCurrentData([])
      let tempcounselor  = counsellor;

    tempcounselor.counselorNo = allCounsellor[index].counselorNo;
    tempcounselor.counselorName = allCounsellor[index].Name

    tempLatestData.map(data=>{
      if(data.assignedDate!=""){
        data.counsellorNo = allCounsellor[index].counselorNo
        data.counsellorName = allCounsellor[index].Name
      }
    })

    setCurrentData(tempLatestData)

    console.log("temp counselor =",tempcounselor,tempLatestData)

    setCounsellor(tempcounselor)
  }


  const addLead = async (e) => {
    e.preventDefault();

    let assignedLeadData = latestData.filter(data=>{
      return(data.counsellorNo!="")
    })

    ContextValue.updateProgress(20);
  
    try {
      // Function to chunk the array into smaller arrays of the specified size
      const chunkArray = (array, chunkSize) => {
        const chunks = [];
        for (let i = 0; i < array.length; i += chunkSize) {
          chunks.push(array.slice(i, i + chunkSize));
        }
        return chunks;
      };
  
      // Split the latestData into chunks of 100 elements each
      const dataChunks = chunkArray(assignedLeadData, 100);
      
      // Process each chunk sequentially
      for (let i = 0; i < dataChunks.length; i++) 
        {
        console.log(`Sending chunk ${i + 1} of ${dataChunks.length}`);
        const firstTenElements = dataChunks[i];

        ContextValue.updateProgress(60);
  
        const res = await fetch('https://blockey.in:8000/counselorLead', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'auth-token': localStorage.getItem('counsellor')
          },
          body: JSON.stringify(firstTenElements),
        });
  
        if (!res.status) {
          throw new Error(`Failed to send chunk ${i + 1}`);
        }
  
        console.log(`Chunk ${i + 1} sent successfully`);
      }
  
      console.log("All chunks sent successfully");
      setLatestData([])
      setCurrentData([])
      setAllCounsellor([])
      setAddLeadStatus(true)
      setCounsellorStatus(false)      

      let tempLeadData = latestData.filter(data=>{
        return(data.counsellorNo=="")
      })
      

      const CACHE_KEY = 'latestFacebookAdData';
      localStorage.setItem(CACHE_KEY, JSON.stringify(tempLeadData));

      await ContextValue.deleteSpecificLatestData(assignedLeadData)
      // await ContextValue.addLatestData(tempLeadData)
      setCurrentData(tempLeadData)
  
      // Once all data is sent, you can update the progress bar and call the success message
      ContextValue.updateProgress(100);
      ContextValue.updateBarStatus(false);
      SuccessMsg();
  
    } catch (error) {
      console.log("Error =", error.message);
      ContextValue.updateProgress(100);
      ContextValue.updateBarStatus(false);
      // Swal.fire({
      //   icon: "error",
      //   title: "Oops...",
      //   text: "Something went wrong!",
      // });
    }
  };

  const SuccessMsg=()=>
    {

    Swal.fire({
      position: 'center',
      icon: 'success',
      title: ``,
      showConfirmButton: false,
      timer: 1500
    })
    
  }
  
  // Example function to get the lead count (You can call this function after sending all chunks)
  const getLead = async () => {
    try {
      ContextValue.updateProgress(20);
      ContextValue.updateBarStatus(true);
  
      let totalLead = await fetch('https://blockey.in:8000/getcounselorLeadCount', {
        method: 'GET',
        headers: {
          'counselorNo': localStorage.getItem('counsellorNo'),
          // 'startDate': rangeDate.startDate,
          // 'endDate': rangeDate.endDate
        }
      });
  
      totalLead = await totalLead.json();
      console.log("Lead count =", totalLead);
  
      ContextValue.updateProgress(100);
      ContextValue.updateBarStatus(false);
  
    } catch (error) {
      console.log("Error fetching lead count =", error.message);
      ContextValue.updateProgress(100);
      ContextValue.updateBarStatus(false);
      // Swal.fire({
      //   icon: "error",
      //   title: "Oops...",
      //   text: "Something went wrong!",
      // });
    }
  };


    const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(parseInt(date.getMonth()) + 1).padStart(2,'0')
    const year = date.getFullYear();
    
    return (`${year}-${month}-${day}`)
    };
  
  // const AssignedCounsellor  =(index,checked)=>{

  
  //   console.log("today date =",todayDate)
  //   let tempLatestData = currentData
  //   setCurrentData([])

  //   if(checked)
  //     {
  //     tempLatestData[index].counsellorName = counsellor.counselorName
  //     tempLatestData[index].counsellorNo = counsellor.counselorNo
  //     tempLatestData[index].assignedDate = todayDate
  //   }

  //   else
  //   {

  //     tempLatestData[index].counsellorName = ""
  //     tempLatestData[index].counsellorNo = ""
  //     tempLatestData[index].assignedDate = ""

  //   }
    

  //   console.log("value = ",checked,index,currentData)

  //   setCurrentData(tempLatestData)

  //   console.log("assigned counsellor working")
  // }


  const AssignedCounsellor = (index, checked) => {
    console.log("today date =", todayDate);
  
    // Create a copy of currentData to ensure immutability
    let tempLatestData = [...currentData];
    let tempAllData  = [...latestData]
    const allDataIndex = latestData.findIndex(item => item.id === tempLatestData[index].id);
    console.log("student id =",tempLatestData[index].id,allDataIndex)
  
    if (checked) {
      // Assign new counsellor values
      tempLatestData[index] = {
        ...tempLatestData[index], // Ensure immutability of the object
        counsellorName: counsellor.counselorName,
        counsellorNo: counsellor.counselorNo,
        assignedDate: todayDate,
      };
      tempAllData[allDataIndex] = {
        ...tempAllData[allDataIndex], // Ensure immutability of the object
        counsellorName: counsellor.counselorName,
        counsellorNo: counsellor.counselorNo,
        assignedDate: todayDate,
      };



    } else {
      // Reset the counsellor values when unchecked
      tempLatestData[index] = {
        ...tempLatestData[index], // Ensure immutability of the object
        counsellorName: "",
        counsellorNo: "",
        assignedDate: "",
      };

      tempAllData[allDataIndex] = {
        ...tempAllData[allDataIndex], // Ensure immutability of the object
        counsellorName: "",
        counsellorNo: "",
        assignedDate: "",
      };
    }
  
    // Update the state with the new data
    setCurrentData(tempLatestData);
    setLatestData(tempAllData)
  
    console.log("value = ", checked, index, tempLatestData);
    console.log("assigned counsellor working");
  };
  

  const filterData  =()=>
    {

    setCampaignStatus(true)
    setCurrentData([])
    console.log("campaign data =",campaign)

    if(campaign.id!=""){
      let tempLeadData = latestData.filter(data=>{
        return (data.campaignId == campaign.id)
      })
      setCurrentData(tempLeadData)
    }

    else{
      setCurrentData(latestData)
    }

  }

  const setCampaignDetail =(value)=>{

    let tempCampaign = campaign;
    if(value!="All"){
      
      tempCampaign.name = allCampaign[value].name
      tempCampaign.id = allCampaign[value].id
    }  
    
    else{
      tempCampaign.name = ""
      tempCampaign.id = ""
    }
    

    setCampaign(tempCampaign)
    console.log("value of campaign =",tempCampaign)

  }

  const setCampaignData = (checked) =>{

    console.log("today date =", todayDate);
  
    // Create a copy of currentData to ensure immutability
    let tempLatestData = [...currentData];
    let tempAllData  = [...latestData]
  
      // Assign new counsellor values
      if(checked){
      currentData.map((data,index)=>{

        tempLatestData[index] = {
          ...tempLatestData[index], // Ensure immutability of the object
          counsellorName: counsellor.counselorName,
          counsellorNo: counsellor.counselorNo,
          assignedDate: todayDate,
        };
        const allDataIndex = latestData.findIndex(item => item.id === currentData[index].id);
        tempAllData[allDataIndex] = {
          ...tempAllData[allDataIndex], // Ensure immutability of the object
          counsellorName: counsellor.counselorName,
          counsellorNo: counsellor.counselorNo,
          assignedDate: todayDate,
        };

      })
    }
    else{
      currentData.map((data,index)=>{

        tempLatestData[index] = {
          ...tempLatestData[index], // Ensure immutability of the object
          counsellorName: "",
          counsellorNo: "",
          assignedDate: "",
        };
        const allDataIndex = latestData.findIndex(item => item.id === currentData[index].id);
        tempAllData[allDataIndex] = {
          ...tempAllData[allDataIndex], // Ensure immutability of the object
          counsellorName: "",
          counsellorNo: "",
          assignedDate: "",
        };

      })
    }
    
    // Update the state with the new data
    setCurrentData(tempLatestData);
    setLatestData(tempAllData)
  
    console.log("value = ", tempLatestData,tempAllData);
    console.log("assigned counsellor working");
  }


 return(
    <div>
            <h3>Latest Lead - {currentData.length}</h3>

          <div className='assigned-counsellor'>
            <div>
            <button onClick={addLead}>Assign Counsellor</button>

            

        {allCounsellor.length > 0 &&
      <select
        name="Course"
        className="custom-select mr-sm-2 filter-btn assigned-counsellor-class"
        onChange={e => {setAssignedCounsellor(e.target.value);setCounsellorStatus(true)}} // Assuming you need to pass the selected value
      >  
      <option selected disabled>-- Select Counsellor --</option>
        {allCounsellor.map((data, index) => {
          return (
            <option key={index} value={index}>{data.Name}</option>
          );
        })}
      </select>
    
  }
        </div>

      
<div>
{ allCampaign.length>0 &&  
                    <select className='filter-btn'
                    onChange={e=>{setCampaignDetail(e.target.value)}}>
          <option selected disabled>select campaign</option>
          <option value="All">All</option>
          {
            allCampaign.map((data,index)=>{
              
              return(
                <option value={index}>{data.name}</option>
              )
            })
          }
        </select>}
  <button className='btn btn-primary ml-2' onClick={filterData}>Search</button>
  </div>
  </div>

{campaignStatus && <div className="selecta-all-container">
  <label>Select All</label>
      <input type="checkbox" disabled={!(counsellorStatus)} onChange={(e)=>{setCampaignData(e.target.checked)}}/>
      </div>}
      
          {currentData.length>0 &&
            <div className="table-container"> <table>

            <tr>
              <th>Campaign</th>
              <th>Created Time</th>
              <th>Name</th>
              <th>City</th>
              <th>Contact</th>
              <th>Email</th>
            </tr>

              {currentData.map((element,index)=>{
                const isChecked = element.counsellorNo === "" ? false : true;
                
        return(
          <tr>
          <td><input type="checkbox" class="data-checkbox" checked={isChecked} disabled={(!counsellorStatus)} onChange={(e)=>{AssignedCounsellor(index,e.target.checked)}}/> {currentData[index].campaignName}</td>
  <td>{currentData[index].created_time}</td>
  
  {
 
    element.students.map(data => {
      if (data.name === "full_name" || data.name=="full name") {
        return (
          <td key={data.id}> {/* Replace `data.id` with a unique key */}
            {data.values}
          </td>
        );
      }
      return null; // Return null if no match, to avoid errors
    })
    
  }
  {
 
    element.students.map(data => {
      if (data.name === "city") {
        return (
          <td key={data.id}> {/* Replace `data.id` with a unique key */}
            {data.values}
          </td>
        );
      }
      return null; // Return null if no match, to avoid errors
    })
    
  }
  {
 
    element.students.map(data => {
      if (data.name === "phone_number") {
        return (
          <td key={data.id}> {/* Replace `data.id` with a unique key */}
            {data.values}
          </td>
        );
      }
      return null; // Return null if no match, to avoid errors
    })
    
  }
  {
 
    element.students.map((data,dataIndex) => {
      if (data.name === "email") {
        return (
          <td key={data.id}> {/* Replace `data.id` with a unique key */}
            {data.values}
          </td>
        );
      }
      return null; // Return null if no match, to avoid errors
    })
    
  }
</tr>

        )
      })}
          </table></div>
}        </div>
 )
};

export default AllLead;
