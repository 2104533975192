// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f9fa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

table{
  border-collapse: collapse;
  width: 100%;
}

/* .table-section{
  overflow: scroll;
} */

th{
  border-bottom: 2px solid grey;
  padding: 10px;
  text-align: initial;
  font-size: 12px;
}
td{
  border-bottom: 1px solid grey;
  padding: 10px;
  font-size: 10px;
}

main{
  width: 90vw;
  margin: auto;
  background-color: white;
  padding: 20px;
}

.filter-section{
  display: flex;
  column-gap: 30px;
  align-items: center;
  margin-bottom: 20px;
}

.filter-btn{
padding: 3px 10px;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;EAClC,yBAAyB;AAC3B;;AAEA;EACE;aACW;AACb;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;;GAEG;;AAEH;EACE,6BAA6B;EAC7B,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB;AACA;EACE,6BAA6B;EAC7B,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,uBAAuB;EACvB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;AACA,iBAAiB;AACjB","sourcesContent":["body {\r\n  margin: 0;\r\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\r\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\r\n    sans-serif;\r\n  -webkit-font-smoothing: antialiased;\r\n  -moz-osx-font-smoothing: grayscale;\r\n  background-color: #f8f9fa;\r\n}\r\n\r\ncode {\r\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\r\n    monospace;\r\n}\r\n\r\ntable{\r\n  border-collapse: collapse;\r\n  width: 100%;\r\n}\r\n\r\n/* .table-section{\r\n  overflow: scroll;\r\n} */\r\n\r\nth{\r\n  border-bottom: 2px solid grey;\r\n  padding: 10px;\r\n  text-align: initial;\r\n  font-size: 12px;\r\n}\r\ntd{\r\n  border-bottom: 1px solid grey;\r\n  padding: 10px;\r\n  font-size: 10px;\r\n}\r\n\r\nmain{\r\n  width: 90vw;\r\n  margin: auto;\r\n  background-color: white;\r\n  padding: 20px;\r\n}\r\n\r\n.filter-section{\r\n  display: flex;\r\n  column-gap: 30px;\r\n  align-items: center;\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.filter-btn{\r\npadding: 3px 10px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
