import React, { useContext, useState, useEffect } from 'react';
import { DataContext } from '../../context/DataState';
import Swal from "sweetalert2";

const StudentHistory = () => {
  let ContextValue = useContext(DataContext);
  const [record, setRecord] = useState([]);

  // Set record based on status only when ContextValue.historyStatus changes
  useEffect(() => {
    const recordType = () => {
      switch (ContextValue.historyStatus.from) {
        case 'demo':
          return ContextValue.historyStatus.data.demoRecord;
        case 'visit':
          return ContextValue.historyStatus.data.visitRecord;
        case 'followup':
          return ContextValue.historyStatus.data.FollowUp;
        default:
          return [];
      }
    };

    setRecord(recordType());
  }, [ContextValue.historyStatus]); // Run effect when historyStatus changes

  const EditRemarks = async (index) => {
    console.log('index of student =', index);
  
    const result = await Swal.fire({
      title: 'Edit Remarks',
      html: `<input id="remark" type="text" class="swal2-input" placeholder="Add Remarks">`,
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Add',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading()
    });
  
    if (result.isConfirmed) {
      const remark = document.getElementById('remark').value;
      let tempRecord = [...record]; // Create a copy of the record array
      tempRecord[index].remark = remark;
      setRecord(tempRecord); // Update the record state with the modified array
  
      // Assuming updateRemarks is an async function that updates the database

      let obj = {
        status:ContextValue.historyStatus.from,
        record:tempRecord,
        id:ContextValue.historyStatus.data.id
      }
      await updateRemarks(obj); 
  
      Swal.fire({
        title: 'Success!',
        text: 'Remark has been updated',
        icon: 'success',
      });
    }
  };
  

  const updateRemarks = async (obj) => {
    ContextValue.updateProgress(30);
    ContextValue.updateBarStatus(true);

    try {
      let url = `https://blockey.in:8000/editremarks`;
      ContextValue.updateProgress(60);

      const res = await fetch(`${url}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
      });

      ContextValue.updateProgress(60);
      ContextValue.updateProgress(100);
      ContextValue.updateBarStatus(false);

      console.log("progress bar 100");

    } 
    catch (error) {
      ContextValue.updateProgress(100);
      ContextValue.updateBarStatus(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
      console.log("error =", error.message);
    }
  };

  return (
    <>
      <div className='student-history-container container p-4'>
        <h4 className='text-center'>{ContextValue.historyStatus.name}</h4>
        <i
          className="fa-solid fa-circle-xmark"
          onClick={() => {
            ContextValue.updateHistory(false, [], "", "");
          }}
        ></i>

        <table className="table">
          <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Remark</th>
              <th scope="col">Edit</th>
            </tr>
          </thead>
          <tbody>
            {record && record.map((element, index) => (
              <tr key={index}>
                <td>{element.date}</td>
                <td>{element.remark}</td>
                <td onClick={() => EditRemarks(index)}><i className="fa-solid fa-pen"></i></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className='blur-background'></div>
    </>
  );
};

export default StudentHistory;
