import React, { useEffect, useState, useContext } from 'react';
import { DataContext } from '../../../context/DataState';
import Swal from "sweetalert2";
import CounsellorFilter from '../../common/CounsellorFilter';

const CounsellorFollowUp = () => {
  const [allCounsellor, setAllCounsellor] = useState([]);
  let ContextValue = useContext(DataContext);
  console.log('context value before=', ContextValue);

  useEffect(() => {
    getAllCounsellor();
    // getFollowUp()
    // getLeadFilter()
  }, []);

  const getAllCounsellor = async () => {
    let allCounselor = await ContextValue.getAllCounselor();
    setAllCounsellor(allCounselor.counselorData);
  };

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(parseInt(date.getMonth()) + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
  };

  const setfollowupStatus = (index, value) => {
    let tempFollowUp = ContextValue.latestData;
    tempFollowUp[index].status = value;
    tempFollowUp[index].finalStatus = value;
    tempFollowUp[index].finalStatusFrom = "Follow Up";
    tempFollowUp[index].finalDate = formatDate(new Date());

    console.log("today date =", formatDate(new Date()), tempFollowUp);

    ContextValue.updateLatestData(tempFollowUp);
  };

  const getReFollowUpDate = (index, from) => {
    console.log('index of student =', index);
    Swal.fire({
      title: 'Add Revisit Date',
      html:
        `<input id="reVisitDate" type="date" class="swal2-input" placeholder="Add Date">
         <input id="remark" type="text" class="swal2-input" placeholder="Add Remarks">`,
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Add',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        const reFollowUpDate = document.getElementById('reVisitDate').value;
        const remark = document.getElementById('remark').value;

        let tempfollowUpData = ContextValue.latestData;
        tempfollowUpData[index].lastFollowUpDate = reFollowUpDate;
        tempfollowUpData[index].finalStatus = "";
        tempfollowUpData[index].finalStatusFrom = "";
        tempfollowUpData[index].finalDate = "";
        tempfollowUpData[index].FollowUp.push({
          date: reFollowUpDate,
          remark: remark
        });

        console.log("temp visit data from re follow up date=", tempfollowUpData);

        ContextValue.updateLatestData(tempfollowUpData);

        Swal.fire({
          title: `Re Follow Up date has been added`,
        });
      }
    });
  };

  const updateFollowUp = async (e) => {
    ContextValue.updateProgress(30);
    ContextValue.updateBarStatus(true);

    e.preventDefault();

    try {
      let url = `https://blockey.in:8000/counselorFollowUp`;
      ContextValue.updateProgress(60);

      const res = await fetch(`${url}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("counsellor")
        },
        body: JSON.stringify(ContextValue.latestData),
      });

      ContextValue.updateProgress(60);
      ContextValue.updateProgress(100);
      ContextValue.updateBarStatus(false);
    } catch (error) {
      ContextValue.updateProgress(100);
      ContextValue.updateBarStatus(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
      console.log("error =", error.message);
    }
  };

  return (
    <div>
      <h3>Follow Up - {ContextValue.latestData.length}</h3>

      <CounsellorFilter function="getFollowUpFilter" counselorNo={localStorage.getItem("counsellorNo")} />

      <button
        type="submit"
        onClick={updateFollowUp}
        className="btn btn-primary"
      >
        Submit Re Follow Up
      </button>

      {ContextValue.latestData.length > 0 && (
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>City</th>
                <th>Contact</th>
                <th>Email</th>
                <th>Follow Up Status</th>
                <th>History</th>
              </tr>
            </thead>
            <tbody>
              {ContextValue.latestData.map((element, index) => (
                <tr key={index}>
                {element.students && (
                  <>
                    {(element.students["full_name"] || element.students["full name"]) && (
                      <td>{element.students["full_name"] || element.students["full name"]}</td>
                    )}
                    {element.students["city"] && <td>{element.students["city"]}</td>}
                    {element.students["phone_number"] && <td>{element.students["phone_number"]}</td>}
                    {element.students["email"] && <td>{element.students["email"]}</td>}
                  </>
                )}
                {allCounsellor.length > 0 && (
                  <td>
                    <select
                      name="status"
                      className="custom-select mr-sm-2"
                      defaultValue={element.status !== "Follow Up" ? element.status : "selected"}
                      onChange={(e) => {
                        setfollowupStatus(index, e.target.value);
                        if (e.target.value === "Follow Up") {
                          getReFollowUpDate(index, "from follow up");
                        }
                      }}
                    >
                      <option disabled value="selected">--Select Follow Up Status--</option>
                      <option value="Registered">Registered</option>
                      <option value="Not Interested">Not Interested</option>
                      <option value="Follow Up">Re Follow Up</option>
                    </select>
                  </td>
                )}
                 <td onClick={() => { ContextValue.updateHistory(true, element, "followup", element.students['full_name'] || element.students['full name']) }}><i class="fa-solid fa-clock-rotate-left"></i></td>
              </tr>
              
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default CounsellorFollowUp;
