import React, { useEffect, useState, useContext } from 'react';
import { DataContext } from '../../../context/DataState';
import Swal from "sweetalert2";
import CounsellorFilter from '../../common/CounsellorFilter';
import StudentHistory from '../../common/StudentHistory';

const CounsellorDemo = () => {
  const [trainer, setTrainer] = useState([]);
  const ContextValue = useContext(DataContext);

  useEffect(() => {
    getAllTrainer();
  }, []);

  const getAllTrainer = async () => {
    const allTrainer = await ContextValue.getTrainer();
    setTrainer(allTrainer.trainerData);
  };

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const setDemoStatus = (value, index) => {
    let tempInpVal = ContextValue.latestData;

    if (value !== "Change Trainer") {
      tempInpVal[index].status = value;
    }

    if (value === "Change Trainer") {
      addTrainers(index);
    }

    if (value === "Demo") {
      addRescheduleDate(index);
    }

    if (value !== "Demo") {
      tempInpVal[index].finalStatus = value;
      tempInpVal[index].finalStatusFrom = "Demo";
      tempInpVal[index].finalDate = formatDate(new Date());
    }

    ContextValue.updateLatestData(tempInpVal);
  };

  const addTrainers = (index) => {
    const trainerOptions = trainer.map((data, i) => (
      `<option value="${i}">${data.name}</option>`
    )).join('');

    Swal.fire({
      title: 'Select Trainer',
      html: `
        <select id="trainer" class="swal2-input">
          <option value="">Select Trainer</option>
          ${trainerOptions}
        </select>
      `,
      showCancelButton: true,
      confirmButtonText: 'Add',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        const trainerIndex = document.getElementById('trainer').value;
        const selectedTrainer = trainer[trainerIndex];

        let tempInpVal = ContextValue.latestData;
        tempInpVal[index].trainer = selectedTrainer.name;
        tempInpVal[index].trainerId = selectedTrainer.trainerId;

        ContextValue.updateLatestData(tempInpVal);

        Swal.fire({
          title: `${selectedTrainer.name} added as trainer`,
        });
      }
    });
  };

  const addRescheduleDate = (index) => {
    Swal.fire({
      title: 'Add Reschedule Date',
      html: `<input id="rescheduleDate" type="date" class="swal2-input" placeholder="Add Date">
      <input id="remark" type="text" class="swal2-input" placeholder="Add Remark">`,
      showCancelButton: true,
      confirmButtonText: 'Add',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        const rescheduleDate = document.getElementById('rescheduleDate').value;
        const remark = document.getElementById('remark').value;

        let tempInpVal = ContextValue.latestData;
        tempInpVal[index].reschedule = rescheduleDate;
        tempInpVal[index].demoRecord.push({
          date:rescheduleDate,
          remark:remark
        })

        tempInpVal[index].finalStatus = "";
        tempInpVal[index].finalStatusFrom = "";
        tempInpVal[index].finalDate = "";

        ContextValue.updateLatestData(tempInpVal);
      }
    });
  };

  const addinpdataMail = async (e) => {
    e.preventDefault();
    ContextValue.updateProgress(30);
    ContextValue.updateBarStatus(true);

    try {
      const url = `https://blockey.in:8000/counselorDemo`;
      const res = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("counsellor"),
          "status": "fromDemo",
        },
        body: JSON.stringify(ContextValue.latestData),
      });

      ContextValue.updateProgress(100);
      ContextValue.updateBarStatus(false);

      if (res.status === 200) {
        Swal.fire('Demo submitted successfully!');
      }
    } catch (error) {
      ContextValue.updateProgress(100);
      ContextValue.updateBarStatus(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
      console.error("error =", error.message);
    }
  };

  return (
    <>
      
    <div>
      <h3>Demo - {ContextValue.latestData.length}</h3>
      <CounsellorFilter function="getDemoFilter" counselorNo={localStorage.getItem("counsellorNo")} />

      <button
        type="submit"
        onClick={addinpdataMail}
        className="btn btn-primary"
      >
        Submit Demo
      </button>

      {ContextValue.latestData.length > 0 &&
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>City</th>
                <th>Contact</th>
                <th>Email</th>
                <th>Status</th>
                <th>Trainer</th>
                <th>History</th>
              </tr>
            </thead>
            <tbody>
              {ContextValue.latestData.map((element, index) => (
                // <tr key={index}>
                //   {element.demoStudent && ( 
                //     <>
                //   {['full_name'||'full name', 'city', 'phone_number', 'email'].map((key) => (
                //     <td key={key}>{element.demoStudent[key]}</td>
                //   ))}
                //   <td>
                //     <select
                //       className="custom-select mr-sm-2"
                //       defaultValue={element.status !== "Demo" ? element.status : "selected"}
                //       onChange={(e) => setDemoStatus(e.target.value, index)}
                //     >
                //       <option disabled selected value="selected">--Select Demo Status--</option>
                //       <option value="Not Joined">Not Joined</option>
                //       <option value="Not Interested">Not Interested</option>
                //       <option value="Demo">Re Demo</option>
                //       <option value="Registered">Registered</option>
                //       <option value="Change Trainer">Change Trainer</option>
                //     </select>
                //   </td>
                //   <td>{element.trainer}</td>
                //   <td>{element.remarks}</td>
                // </>)}</tr>
                <tr key={index}>
  {element.demoStudent && (
    <>
      {['full_name', 'city', 'phone_number', 'email'].map((key) => (
        <td key={key}>
          {key === 'full_name' && !element.demoStudent['full_name'] && element.demoStudent['full name']
            ? element.demoStudent['full name']
            : element.demoStudent[key]}
        </td>
      ))}
      <td>
        <select
          className="custom-select mr-sm-2"
          defaultValue={element.status !== "Demo" ? element.status : "selected"}
          onChange={(e) => setDemoStatus(e.target.value, index)}
        >
          <option disabled selected value="selected">--Select Demo Status--</option>
          <option value="Not Joined">Not Joined</option>
          <option value="Not Interested">Not Interested</option>
          <option value="Demo">Re Demo</option>
          <option value="Registered">Registered</option>
          <option value="Change Trainer">Change Trainer</option>
        </select>
      </td>
      <td>{element.trainer}</td>
      <td onClick={() => { ContextValue.updateHistory(true, element, "demo", element.demoStudent['full_name'] || element.demoStudent['full name']) }}><i class="fa-solid fa-clock-rotate-left"></i></td>

    </>
  )}
</tr>

              ))}
            </tbody>
          </table>
        </div>
      }
    </div>
    </>
  );
};

export default CounsellorDemo;
