import React, { useEffect, useState, useContext } from 'react';
import {DataContext} from '../../../context/DataState'
import AdminFilter from '../../common/AdminFilter';

const AllLead = () => {



  let ContextValue = useContext(DataContext);
  console.log('context value before=',ContextValue)

  useEffect(()=>{

    console.log('context value =',ContextValue)


  },[])



   const addLead = async (e) => {
    e.preventDefault();

    ContextValue.updateProgress(20);
  
    try {
      // Function to chunk the array into smaller arrays of the specified size
      const chunkArray = (array, chunkSize) => {
        const chunks = [];
        for (let i = 0; i < array.length; i += chunkSize) {
          chunks.push(array.slice(i, i + chunkSize));
        }
        return chunks;
      };
  
      // Split the latestData into chunks of 100 elements each
      const dataChunks = chunkArray(ContextValue.currentData, 100);
      
      // Process each chunk sequentially
      for (let i = 0; i < dataChunks.length; i++) 
        {
        console.log(`Sending chunk ${i + 1} of ${dataChunks.length}`);
        const firstTenElements = dataChunks[i];

        ContextValue.updateProgress(60);
  
        const res = await fetch('https://blockey.in:8000/counselorLead', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'auth-token': localStorage.getItem('counsellor')
          },
          body: JSON.stringify(firstTenElements),
        });
  
        if (!res.status) {
          throw new Error(`Failed to send chunk ${i + 1}`);
        }
  
        console.log(`Chunk ${i + 1} sent successfully`);
      }
  
      console.log("All chunks sent successfully");

      let tempLeadData = ContextValue.currentData.filter(data=>{
        return(data.counsellorNo=="")
      })

      const CACHE_KEY = 'latestFacebookAdData';
      localStorage.setItem(CACHE_KEY, JSON.stringify(tempLeadData));
  
      // Once all data is sent, you can update the progress bar and call the success message
      ContextValue.updateProgress(100);
      ContextValue.updateBarStatus(false);
      // SuccessMsg();
  
    } catch (error) {
      console.log("Error =", error.message);
      ContextValue.updateProgress(100);
      ContextValue.updateBarStatus(false);
      // Swal.fire({
      //   icon: "error",
      //   title: "Oops...",
      //   text: "Something went wrong!",
      // });
    }
  };


  const setAssignedCounsellor = (index)=>{

    
    var selectElement = document.getElementsByClassName("assigned-counsellor-class");

    // let tempArr = tempObj[allCounsellor[selectElement[index].selectedIndex].counselorNo]

    let counsellorIndex = (selectElement[index].selectedIndex)-1
    let dataIndex = index;

  console.log("value =",counsellorIndex,index)

  let tempAllLead = ContextValue.currentData

  tempAllLead[dataIndex].counsellorNo = ContextValue.allCounsellor[counsellorIndex].counselorNo;
  tempAllLead[dataIndex].counsellorName = ContextValue.allCounsellor[counsellorIndex].Name

  console.log("tempAllLead =",tempAllLead)

  // setLatestData(tempAllLead)
  ContextValue.updateCurrentData(tempAllLead)

  }

  const getPhone = (e)=>{
    const filteredData = []
    ContextValue.updateCurrentData([])
  // setLatestData([])
    ContextValue.latestData.map((element,index) => {
    element.students.map((data) => {
        if (data.name === "phone_number") {
          const normalizedValues = data.values.map(value => value.replace(/\+/, '')); // Remove '+' if present
          const sanitizedTargetValue = e.target.value.replace(/\+/, ''); // Also sanitize e.target.value
    
          // Check if sanitizedTargetValue is in normalizedValues
          console.log("include condition outside =",normalizedValues,sanitizedTargetValue,normalizedValues[0].includes(sanitizedTargetValue))

          if(normalizedValues[0].includes(sanitizedTargetValue)){
            console.log("include condition ")
            filteredData.push(ContextValue.latestData[index])
          }        
        }
        
      });
    });
    
    console.log("filterdata",filteredData);
    ContextValue.updateCurrentData(filteredData)
    // setLatestData(filteredData)
    
  }



 return(
    <div>
            <h3>Assigned Lead - {ContextValue.currentData.length}</h3>

        <AdminFilter function="getLeadFilter"/>

        <div className="search-section">
          <input type="number" placeholder='Search By Phone Number' onChange={e=>{getPhone(e)}}/>
        </div>
        
        {ContextValue.currentData.length>0 && <button onClick={addLead}>Assign Counsellor</button>}
        {ContextValue.currentData &&
            <div className="table-container"> <table>

            <tr>
              <th>Campaign</th>
              <th>Assigned Date</th>
              <th>Name</th>
              <th>City</th>
              <th>Contact</th>
              <th>Email</th>


          <th>Assigned Counsellor</th>
          <th>Student Status</th>
            </tr>

              {ContextValue.currentData.map((element,index)=>{
        return(
          <tr>
  <td>{ContextValue.currentData[index].campaignName}</td>
  <td>{ContextValue.currentData[index].assignedDate}</td>
  {
 
    element.students.map(data => {
      if (data.name === "full_name" || data.name=="full name") {
        return (
          <td key={data.id}> {/* Replace `data.id` with a unique key */}
            {data.values}
          </td>
        );
      }
      return null; // Return null if no match, to avoid errors
    })
    
  }
  {
 
    element.students.map(data => {
      if (data.name === "city") {
        return (
          <td key={data.id}> {/* Replace `data.id` with a unique key */}
            {data.values}
          </td>
        );
      }
      return null; // Return null if no match, to avoid errors
    })
    
  }
  {
 
    element.students.map(data => {
      if (data.name === "phone_number") {
        return (
          <td key={data.id}> {/* Replace `data.id` with a unique key */}
            {data.values}
          </td>
        );
      }
      return null; // Return null if no match, to avoid errors
    })
    
  }
  {
 
    element.students.map((data,dataIndex) => {
      if (data.name === "email") {
        return (
          <td key={data.id}> {/* Replace `data.id` with a unique key */}
            {data.values}
          </td>
        );
      }
      return null; // Return null if no match, to avoid errors
    })
    
  }
 
 
    <td>
      <select
        name="Course"
        className="custom-select mr-sm-2 assigned-counsellor-class"
        onChange={e => setAssignedCounsellor(index)}
        defaultValue={element.counsellorName!=""?element.counsellorName:"selected"}  // Assuming you need to pass the selected value
      >  
      {console.log("counselor name =",element.counsellorName,element.counsellorName!="")}
      
      <option disabled selected value="selected">--Select Counsellor--</option>
        {ContextValue.allCounsellor.map((data, index) => {
          return (
            
            <option key={index} value={data.Name}>{data.Name}</option>
          );
        })}
      </select>
    </td>
    
  
 
 <td>{element.status==""?"No Report":`Assigned to ${element.status}`}</td>
</tr>

        )
      })}
          </table></div>
}       </div>
 )
};

export default AllLead;
