import React, { useState } from 'react'
// import StudentContext from './StudentContext'
import { createContext } from "react";
import Swal from 'sweetalert2'

export const DataContext = createContext();



const DataState = (props) => {
    const [allCounselor, setAllCounselor] = useState()
    const [progress, setProgress]  = useState(0)
    const [barStatus, setBarStatus]  = useState(false)
    const [latestData, setLatestData] = useState([])
    const [currentData, setCurrentData] = useState([])
    const [allCounsellor, setAllCounsellor] = useState([])
    const [manualStatus, setManualStatus] = useState(false)
    const [historyStatus, setHistroyStatus] = useState({
      status:false,
      data:[],
      from:"",
      name:""
    })

    const updateManualStatus = (value) =>{
      setManualStatus(value)
    }

    const updateProgress = (length)=>{

      setProgress(length)
      console.log('progress length =',length)
      
    }
    const updateBarStatus = (value)=>{
      console.log("value for bar status=",value)
      setBarStatus(value)
    }    

    const updateLatestData = (data)=>{
      setLatestData(data)
    }
    const updateCurrentData = (data)=>{
      setCurrentData(data)
    }

    const getAllCounselor = async () => {
      updateProgress(30);
        updateBarStatus(true);
        let allCounselor = await fetch("https://blockey.in:8000/getAllCounselor", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          }
        });

        updateProgress(60);
    
        allCounselor = await allCounselor.json()
        // console.log('state counselor =',allCounselor)
        setAllCounselor(allCounselor)
        updateProgress(100);
        updateBarStatus(false);
        setAllCounsellor(allCounselor.counselorData)
        return allCounselor
      }
    const getAllCampaign = async () => {

      updateProgress(30);
      updateBarStatus(true);
        let allCampaign = await fetch("https://blockey.in:8000/getfacebookCampaignData", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          }
        });

        updateProgress(60);
    
        allCampaign = await allCampaign.json()

        updateProgress(100);
        updateBarStatus(false);
        // console.log('state campaign =',allCampaign,barStatus)
        return allCampaign
      }


      const getTrainer = async () => {

        updateProgress(30);
        updateBarStatus(true);
          let allTrainer = await fetch("https://blockey.in:8000/getTrainers", {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            }
          });
  
          updateProgress(60);
      
          allTrainer = await allTrainer.json()
  
          updateProgress(100);
          updateBarStatus(false);
          // console.log('alltrainer =',allTrainer)
          return allTrainer
        }

        const addExpiry = async(expiry)=>{

          console.log("add expiry function",expiry)

          try{
          const res = await fetch(`https://blockey.in:8000/addExpiry`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "expiry": expiry
            },
        
          });
        }
        catch(error){
          Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
        }

        }

const addLatestData = async (data) => {
  console.log("add temp latest data function", data.length);

  // Split data into chunks of 100
  const chunkSize = 100;
  const chunks = [];
  for (let i = 0; i < data.length; i += chunkSize) {
    chunks.push(data.slice(i, i + chunkSize));
  }

  // Send each chunk sequentially
  updateProgress(30);
  updateBarStatus(true);
  for (let i = 0; i < chunks.length; i++) {
    try {
      console.log(`Sending batch ${i + 1} of ${chunks.length}`);
      const res = await fetch(`https://blockey.in:8000/addtemporaryLatest`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(chunks[i]), // Send each batch of 100 entries
      });

      if (!res.ok) {
        throw new Error(`Batch ${i + 1} failed to send`);
      }

      console.log(`Batch ${i + 1} successfully sent`);
    } 
    catch (error) {
      console.error(`Error sending batch ${i + 1}:`, error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `Something went wrong with batch ${i + 1}!`,
      });
      break; // Stop if any batch fails
    }
  }

  updateProgress(100);
  updateBarStatus(false);

  console.log("All data batches processed");
};

const getLeadFilter = async (counsellorNo,campaignId,startDate,endDate) => {

  setLatestData([])
  setCurrentData([])

  console.log("context getfilter =",counsellorNo,campaignId,startDate,endDate)

  try {
    updateProgress(20);
    updateBarStatus(true);

    let totalLead = await fetch('https://blockey.in:8000/getcounselorLeadFilter', {
      method: 'GET',
      headers: {
        'counselorNo': counsellorNo,
        'campaignId': campaignId,
        'startDate': startDate,
        'endDate': endDate
      }
    });

    totalLead = await totalLead.json();
    console.log("Lead count =", totalLead);
    updateProgress(100);
    updateBarStatus(false);

    setLatestData(totalLead.totalLead)
    setCurrentData(totalLead.totalLead)
    return totalLead;

    
  }
   catch (error) {
    console.log("Error fetching lead count =", error.message);
    updateProgress(100);
    updateBarStatus(false);
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong!",
    });
  }
};


const getDemoFilter = async(counsellorNo,campaignId,startDate,endDate)=>{
  setLatestData([])

  try {
    updateProgress(20);
    updateBarStatus(true);

    let totalLead = await fetch('https://blockey.in:8000/getcounselorDemoFilter', {
      method: 'GET',
      headers: {
       'counselorNo': counsellorNo,
        'campaignId': campaignId,
        'startDate': startDate,
        'endDate': endDate
      }
    });

    totalLead = await totalLead.json();
    console.log("Lead count from context=", totalLead);
    setLatestData(totalLead.totalLead)

    updateProgress(100);
    updateBarStatus(false);

  }
   catch (error) {
    console.log("Error fetching lead count =", error.message);
    updateProgress(100);
    updateBarStatus(false);
    // Swal.fire({
    //   icon: "error",
    //   title: "Oops...",
    //   text: "Something went wrong!",
    // });
  }
}
const getFollowUpFilter = async(counsellorNo,campaignId,startDate,endDate)=>{
  setLatestData([])

  try {
    updateProgress(20);
    updateBarStatus(true);

    let totalLead = await fetch('https://blockey.in:8000/getcounselorFollowUpFilter', {
      method: 'GET',
      headers: {
       'counselorNo': counsellorNo,
        'campaignId': campaignId,
        'startDate': startDate,
        'endDate': endDate
      }
    });

    totalLead = await totalLead.json();
    console.log("Lead count from context=", totalLead);
    setLatestData(totalLead.totalLead)

    updateProgress(100);
    updateBarStatus(false);

  }
   catch (error) {
    console.log("Error fetching lead count =", error.message);
    updateProgress(100);
    updateBarStatus(false);
    // Swal.fire({
    //   icon: "error",
    //   title: "Oops...",
    //   text: "Something went wrong!",
    // });
  }
}
const getVisitFilter = async(counsellorNo,campaignId,startDate,endDate)=>{
  setLatestData([])

  try {
    updateProgress(20);
    updateBarStatus(true);

    let totalLead = await fetch('https://blockey.in:8000/getcounselorVisitFilter', {
      method: 'GET',
      headers: {
       'counselorNo': counsellorNo,
        'campaignId': campaignId,
        'startDate': startDate,
        'endDate': endDate
      }
    });

    totalLead = await totalLead.json();
    console.log("Lead count from context=", totalLead);
    setLatestData(totalLead.totalLead)

    updateProgress(100);
    updateBarStatus(false);

  }
   catch (error) {
    console.log("Error fetching lead count =", error.message);
    updateProgress(100);
    updateBarStatus(false);
    // Swal.fire({
    //   icon: "error",
    //   title: "Oops...",
    //   text: "Something went wrong!",
    // });
  }
}
const getCounsellorRingingConnectedRegisteredFilter = async(counsellorNo,campaignId,startDate,endDate,from,status)=>{
  setLatestData([])
  console.log("get lead filter datastate status=",status)


  try {
    updateProgress(20);
    updateBarStatus(true);

    let totalLead = await fetch('https://blockey.in:8000/getCounsellorRingingConnectedRegistered', {
      method: 'GET',
      headers: {
       'counselorNo': counsellorNo,
        'campaignId': campaignId,
        'startDate': startDate,
        'endDate': endDate,
        'from':from,
        'status':status
      }
    });

    totalLead = await totalLead.json();
    console.log("Lead count from context=", totalLead);
    setLatestData(totalLead.totalLead)

    updateProgress(100);
    updateBarStatus(false);

  }
   catch (error) {
    console.log("Error fetching lead count =", error.message);
    updateProgress(100);
    updateBarStatus(false);
    // Swal.fire({
    //   icon: "error",
    //   title: "Oops...",
    //   text: "Something went wrong!",
    // });
  }
}


        const getExpiry  = async()=>{

          let expiry = await fetch("https://blockey.in:8000/getExpiry", {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            }
          });

          
          expiry = await expiry.json()
          console.log("expiry get =",expiry)

          return expiry.expiry
          

        }

        const getTempLatestData  = async()=>{

          let tempLatestData = await fetch("https://blockey.in:8000/getTemporaryLatest", {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            }
          });

          tempLatestData = await tempLatestData.json()

          console.log("temp lates data =",tempLatestData)

          return tempLatestData.tempLatestData

        }

        const deleteLatestData = async()=>{
          let deletetempLatestData = await fetch("https://blockey.in:8000/deletetemporaryLatest", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            }
          });
        }
        const deleteSpecificLatestData = async(data)=>{
         
          console.log("add temp latest data function", data);

          // Split data into chunks of 100
          const chunkSize = 100;
          const chunks = [];
          for (let i = 0; i < data.length; i += chunkSize) {
            chunks.push(data.slice(i, i + chunkSize));
          }
        
          // Send each chunk sequentially
          for (let i = 0; i < chunks.length; i++) {
            try {
              console.log(`Sending batch ${i + 1} of ${chunks.length}`);
              const res = await fetch(`https://blockey.in:8000/deleteAssignedtemporaryLatest`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(chunks[i]), // Send each batch of 100 entries
              });
        
              if (!res.ok) {
                throw new Error(`Batch ${i + 1} failed to send`);
              }
        
              console.log(`Batch ${i + 1} successfully sent`);
            } 
            catch (error) {
              console.error(`Error sending batch ${i + 1}:`, error);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: `Something went wrong with batch ${i + 1}!`,
              });
              break; // Stop if any batch fails
            }
          }
        
          console.log("All data batches processed");

        }


      const getLatestData = async () => {
        console.log("calling get latest data");
        const CACHE_KEY = 'latestFacebookAdData';
        const CACHE_EXPIRY_KEY = 'latestFacebookAdDataExpiry';
        // const CACHE_EXPIRY_TIME = 60 * 60 * 1000; // 1 hour in milliseconds
        const CACHE_EXPIRY_TIME = 20 * 60 * 1000; // 20 minutes in milliseconds

        console.log("expiry time =",Date.now() + CACHE_EXPIRY_TIME)

      
        const cachedData = localStorage.getItem(CACHE_KEY);
        const cachedExpiry = localStorage.getItem(CACHE_EXPIRY_KEY);

        const expiry = await getExpiry()
        const tempLatestData = await getTempLatestData()

        // console.log("get expiry and lates data =",cachedExpiry,expiry[0].expiry,tempLatestData)
      
        // Check if cached data exists and is still valid
        let tempLeaData;
        if (tempLatestData.length>0 && expiry[0].expiry && Date.now() < parseInt(expiry[0].expiry, 10)) 
          {
            // console.log('Using cached data', JSON.parse(cachedData).length, JSON.parse(cachedData));
            // tempLeaData = JSON.parse(tempLatestData);
    
          console.log("all lead data from state hook =", tempLatestData);
          return tempLatestData;
        }
      
        // If no valid cache, proceed to fetch the latest data
        updateProgress(30);
        updateBarStatus(true);
      
        try {
            let totalLatestData = await fetch('https://blockey.in:8000/getLatestFacebookAdData', {
                method: 'GET',
            });
      
            updateProgress(60);
      
            totalLatestData = await totalLatestData.json();
      
            updateProgress(100);
            updateBarStatus(false);
      
            // Store the response data in the cache
            
      
            // Update the state with the latest data
            tempLeaData = totalLatestData.data;
            console.log('All latest lead data =', tempLeaData.length,totalLatestData,totalLatestData.data);    
            
            // Process and update the tempAdLeadData
            let tempAdLeadData = tempLeaData.map(data => {
                return {
                    id: data.data_id,
                    adId: data.ad_id,
                    adName: data.ad_name,
                    campaignId: data.campaign_id,
                    campaignName: data.campaign_name,
                    students: data.field_data,
                    created_time: data.created_time.split("T")[0],
                    assignedDate: "",
                    counsellorNo:"",
                    counsellorName:"",
                    status:"",
                    remarks:""
                };
            });

            if(tempLeaData.length>0){
              await deleteLatestData()
              await addExpiry(Date.now() + CACHE_EXPIRY_TIME)
              await addLatestData(tempAdLeadData)
            localStorage.setItem(CACHE_KEY, JSON.stringify(tempAdLeadData));
            localStorage.setItem(CACHE_EXPIRY_KEY, Date.now() + CACHE_EXPIRY_TIME);
            }
    
            console.log("all lead data from state hook =", tempAdLeadData);

            return tempAdLeadData;
    
        } 
        catch (error) {
            updateProgress(100);
            updateBarStatus(false);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
            });
        }
    };

const SuccessMsg=()=>{

  Swal.fire({
    position: 'center',
    icon: 'success',
    title: 'Candidate Has Been Registered',
    showConfirmButton: false,
    timer: 1500
  })
  
}

const updateHistory = (value,data,from, name)=>{
  console.log("update history context =",value)
  setHistroyStatus({...historyStatus,["status"]:value, ["data"]:data, ["from"]:from,["name"]:name})
}

const getAllMainSubCourse = async (id) => {

  let AllCourse = await fetch(`https://blockey.in:8000/allSubMainCourse`, {
    method: "GET",
  });

  AllCourse = await AllCourse.json()
  return AllCourse
}

  return (
    <div>
      <DataContext.Provider value={{ student:"checking", allCounselor,getLatestData, getAllCounselor, updateProgress, updateBarStatus, progress, barStatus,getAllCampaign,getTrainer,addLatestData,deleteLatestData,deleteSpecificLatestData,getLeadFilter,updateLatestData,updateCurrentData,currentData,latestData,allCounsellor,getDemoFilter,getFollowUpFilter,getVisitFilter,getCounsellorRingingConnectedRegisteredFilter,historyStatus,updateHistory,getAllMainSubCourse,updateManualStatus,manualStatus}}>
        {props.children}
      </DataContext.Provider>
    </div>
  )
}

export default DataState;