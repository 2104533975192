import React, { useEffect, useContext } from 'react';
import { DataContext } from '../../../context/DataState';
import AdminFilter from '../../common/AdminFilter';

const NotJoined = () => {
  const ContextValue = useContext(DataContext);
  console.log('context value before=', ContextValue);

  useEffect(() => {
    // Any side effects or API calls can be handled here
  }, []);

  const addLead = async (e) => {
    e.preventDefault();

    ContextValue.updateProgress(20);

    try {
      // Function to chunk the array into smaller arrays of the specified size
      const chunkArray = (array, chunkSize) => {
        const chunks = [];
        for (let i = 0; i < array.length; i += chunkSize) {
          chunks.push(array.slice(i, i + chunkSize));
        }
        return chunks;
      };

      // Split the latestData into chunks of 100 elements each
      const dataChunks = chunkArray(ContextValue.latestData, 100);

      // Process each chunk sequentially
      for (let i = 0; i < dataChunks.length; i++) {
        console.log(`Sending chunk ${i + 1} of ${dataChunks.length}`);
        const firstTenElements = dataChunks[i];

        ContextValue.updateProgress(60);

        const res = await fetch('https://blockey.in:8000/counselorLead', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'auth-token': localStorage.getItem('counsellor'),
          },
          body: JSON.stringify(firstTenElements),
        });

        if (!res.ok) {
          throw new Error(`Failed to send chunk ${i + 1}`);
        }

        console.log(`Chunk ${i + 1} sent successfully`);
      }

      console.log('All chunks sent successfully');

      let tempLeadData = ContextValue.latestData.filter((data) => data.counsellorNo === '');

      const CACHE_KEY = 'latestFacebookAdData';
      localStorage.setItem(CACHE_KEY, JSON.stringify(tempLeadData));

      // Once all data is sent, update the progress bar and hide it
      ContextValue.updateProgress(100);
      ContextValue.updateBarStatus(false);
    } catch (error) {
      console.error('Error =', error.message);
      ContextValue.updateProgress(100);
      ContextValue.updateBarStatus(false);
    }
  };

  const setAssignedCounsellor = (index) => {
    const selectElement = document.getElementsByClassName('assigned-counsellor-class');
    const counsellorIndex = selectElement[index].selectedIndex;
    const dataIndex = index;

    let tempNotJoined = [...ContextValue.latestData]; // Clone latestData array

    // Update the counsellor information in the selected element
    tempNotJoined[dataIndex].counsellorNo = ContextValue.allCounsellor[counsellorIndex].counselorNo;
    tempNotJoined[dataIndex].counsellorName = ContextValue.allCounsellor[counsellorIndex].Name;

    ContextValue.updateLatestData(tempNotJoined); // Update the context data
  };

  return (
    <div>
      <h3>Not Joined - {ContextValue.latestData.length}</h3>

      <AdminFilter function="getCounsellorRingingConnectedRegisteredFilter" status="Not Joined" fromStatus="true" />


      {ContextValue.latestData && (
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Campaign</th>
                <th>Assigned Date</th>
                <th>Name</th>
                <th>City</th>
                <th>Contact</th>
                <th>Email</th>
                <th>Assigned Counsellor</th>
                <th>Remarks</th>
              </tr>
            </thead>
            <tbody>
              {ContextValue.latestData.map((element, index) => (
                <tr key={index}>
                  <td>{element.campaignName}</td>
                  <td>{element.assignedDate}</td>

                  {/* Conditional Rendering of students */}
                  {element.students && (
                    <>
                      {/* Name */}
                      <td>
                        {element.students.map((data) =>
                          data.name === 'full_name' || data.name === 'full name' ? data.values : null
                        )}
                      </td>

                      {/* City */}
                      <td>
                        {element.students.map((data) =>
                          data.name === 'city' ? data.values : null
                        )}
                      </td>

                      {/* Phone Number */}
                      <td>
                        {element.students.map((data) =>
                          data.name === 'phone_number' ? data.values : null
                        )}
                      </td>

                      {/* Email */}
                      <td>
                        {element.students.map((data) =>
                          data.name === 'email' ? data.values : null
                        )}
                      </td>
                    </>
                  )}

                  {/* Assigned Counsellor */}
                  <td>
                    <select
                      name="Course"
                      className="custom-select mr-sm-2 assigned-counsellor-class"
                      onChange={() => setAssignedCounsellor(index)}
                      defaultValue={element.counsellorName || 'selected'}
                    >
                      <option disabled value="selected">
                        --Select Counsellor--
                      </option>
                      {ContextValue.allCounsellor.map((data, counsellorIndex) => (
                        <option key={counsellorIndex} value={data.Name}>
                          {data.Name}
                        </option>
                      ))}
                    </select>
                  </td>

                  {/* Remarks */}
                  <td>{element.remarks}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default NotJoined;
